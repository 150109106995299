body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 300px ;
  font-size: 1.1vrem ;
  background: lightblue ;
}


.dark {
    font-size: .9rem; 
    background: purple ; 
    border: blue 1px solid;
    padding: 15px ;
    margin : 1px ;
    border-radius: 25px ;
}

.limcard {
  height: 150px ;
}

.homepage {
  height: 300px ;
  padding: 0px ;
  border: white 1px solid;
  border-radius: 25px ;
  font-size: 1.3rem ;
  margin: 5px;
  color: sienna;
  background: sienna;
  ;
}

.HomeImage {
    height: 300px ; 
}

h5 {
    color: goldenrod ; 
    }

i {
    color: skyblue ;
}

b {
    color: darkblue ;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  color: #FFFF00 ;
}

.JsonQuery {
  text-align: center;
  padding-left: 10px ;
  padding-right: 10px ;
}

.griddisplay {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr ;
}

.table {
  border: 0px solid #79F ;
  border-radius : 25px ;
}
.table-stripped {
    background-color: brown ; 
}


.tr {
  border-radius : 25px ;
}


.label {
  border: 2px solid #000 ;
  border-radius: 15px ;
  background-color: black ; 
  color: white;
  text-align: right;
  padding-left: 5px ;
}
.control {
  border: 2px solid #000 ;
  border-radius: 15px ;
  background-color: grey ; 
  color: yellow ;
  text-align: left;
}


.emplcard {
  border: 2px solid #000 ;
  border-radius: 15px ;
  padding-right: 5px ;
  margin-right: 10px ; 
  background-color: lightyellow ; 
  width: 155px;
  height:250px ; 
}
.skill {
  font-family:Arial ;
  font-size: .9vmin) ;
}

li {
  list-style:none ; 
  text-align: left ;
  margin-left: 3px ; 
  padding-left: 5px ;

}
.JsonQuery {
  font-family:Arial ;
  font-size: calc(2px + 1.8vmin) ;
  color: brown ; 
  height: 840px ; 
  width:  840px ;
  border: 2px solid #000 ;
  border-radius: 25px ;
  overflow: auto ;
  text-align: center;
  background-color: skyblue ; 
  margin-bottom: 10rem;
  padding-bottom: 2rem;
}

.JsonQuery-1 {
  font-family:Arial ;
  font-size: calc(2px + 1.8vmin) ;
  color: goldenrod ; 
  height: 840px ; 
  width:  840px ;
  border: 2px solid #000 ;
  border-radius: 5px ;
  overflow: auto ;
  text-align: center;
  background-color: grey ; 
  margin-bottom: 10rem;
  padding-bottom: 2rem;
  background-size: 10px 10px ;
  background-image:
    linear-gradient(to right, lightblue 1px, transparent 1px),
    linear-gradient(to bottom, lightblue 1px, mintcream 1px);
}

.JsonQuery-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: blueviolet;
  border-radius: 25px ;
}

.JsonQuery-node {
    border-radius: 10px ; 
}

.JsonQuery-dept {
    border-radius: 10px ; 
    background-color: black ; 
    color: black ; 
    padding-left: 5rem ; 
}

.JsonQuery-empl {
    border-radius: 10px ; 
}

.JsonQuery-link {
  color: #61dafb;
  padding-right: 1rem;
  padding-bottom: 1rem;
}

.JsonQuery-link:link {
  color: green ;
}

.JsonQuery-link:visited {
  color: blue ; 
}

.JsonQuery-link:hover {
  color: burlywood ;
}

.JsonQuery-link:active {
  color: brown ;
}

